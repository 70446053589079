import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { HeroImage, Intro, Category, Pagination, Section } from "@components/Work"
import { Carousel } from "@components/Carousel"
import { CursorMarker } from "@components/CursorMarker"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import LogoOne from "@svg/work/xcelis/logo-01.svg"
import workData from "@json/work.json"

const XcelisPage = ({ data }) => {
  const {
    logoTwoImage,
    logoThreeImage,
    logoFourImage,
    webpageOneImage,
    webpageTwoImage,
    printOneDesktopImage,
    printOneTabletImage,
    printTwoDesktopImage,
    printTwoTabletImage,
    printThreeDesktopImage,
    printThreeTabletImage,
    printFourDesktopImage,
    printFourTabletImage,
    printFiveTabletImage,
    printAdditionalOneImage,
    printAdditionalTwoImage,
    fontsDesktopImage,
    fontsTabletImage,
    fontsMobileImage,
  } = data
  const { name, headline, theme, url } = workData["xcelis"]
  const { themeColor } = usePageSetup({
    themeColor: theme.background,
    navigationColor: theme.navigationColor,
    displayGeneralForm: false,
  })

  return (
    <Layout>
      <Seo title="Dupont Xcelis" description={headline} />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-clamp-36-54 pt-clamp-16-26">
          <Hero.Title>
            <h1
              className="tracking-normal text-white text-18"
              dangerouslySetInnerHTML={{
                __html: name,
              }}
            />
            <h2
              data-aos="stagger"
              className="text-headline mt-clamp-6-8"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-white": headline,
                }),
              }}
            />
          </Hero.Title>
        </Hero>
      </section>

      <Arrow colorTop="bg-white" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />

      <main className="overflow-hidden">
        <HeroImage client="xcelis" />
        <Category>B2B branding, Website development</Category>
        <Intro>
          <Intro.Overview>
            <Intro.Title>Fueling success with a fired-up story.</Intro.Title>
            <div className="mt-10">
              <p>
                DuPont has a strong track record of helping fuel ethanol producers get maximum value from the grains
                they process. So their grain processing division had grown—but due to a variety of acquisitions, its
                brand had become diluted and its product portfolio was fractured.
              </p>
              <p>
                To enhance their visibility and strengthen their voice in the market, they needed an ownable identity
                and a compelling story to tell.
              </p>
              <p>
                JK saw an opportunity for them to break through the sea of sameness in their space by uniting their fuel
                ethanol products and services on a single platform, with strong and cohesive messaging that would
                resonate with their customers.
              </p>
              <p>
                Because we know the best stories are built on insights, we began by surveying customers and employees to
                get real-world feedback on how they were perceived.
              </p>
            </div>
          </Intro.Overview>
          <Intro.Deliverables url={url}>
            <li>Branding</li>
            <li>Messaging</li>
            <li>Naming</li>
            <li>Customer &amp; stakeholder research</li>
            <li>Brand guidelines</li>
            <li>Website design</li>
            <li>Trade show graphics</li>
            <li>Product packaging</li>
            <li>Digital &amp; print ad campaigns</li>
            <li>Video</li>
            <li>Posters</li>
            <li>Blog posts/articles</li>
            <li>Copywriting</li>
          </Intro.Deliverables>
        </Intro>

        <section className="pt-clamp-25-56">
          <div>
            <div className="container relative flex">
              <CursorMarker>
                <CursorMarker.Gallery />
              </CursorMarker>
              <div className="w-full sm:w-5/6 lg:w-1/2">
                <Carousel>
                  <Carousel.Swiper spaceBetween={16} centeredSlides={false}>
                    <Carousel.Slide>
                      <div data-aos="fade-up" className="w-full pointer-events-none">
                        <LogoOne />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="200" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoTwoImage)}
                          alt="Logo 2"
                        />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="400" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoThreeImage)}
                          alt="Logo 3"
                        />
                      </div>
                    </Carousel.Slide>
                    <Carousel.Slide>
                      <div data-aos="fade-up" data-aos-delay="600" className="w-full pointer-events-none">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(logoFourImage)}
                          alt="Logo 4"
                        />
                      </div>
                    </Carousel.Slide>
                  </Carousel.Swiper>
                </Carousel>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-25-41">
          <Section className="col-span-9">
            <Section.Description>
              <p>
                Informed by their input, we called the new platform XCELIS—reflecting DuPont’s focus on helping their
                customers excel. Together with a new tagline, “Fueling you forward,” it clearly conveys their drive to
                understand their customers—and their fierce determination to fuel their growth and success.
              </p>
            </Section.Description>
          </Section>

          <div className="container flex justify-center pt-clamp-26-35">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Colors
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-16">
                <div
                  className="w-full pb-5 pl-5 text-white pt-39"
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(0,179,228,1) 0%, rgba(0,137,197,1) 50%, rgba(0,100,169,1) 100%)",
                  }}
                >
                  <span className="font-bold text-18">#005CB9</span>
                </div>
                <div className="grid w-full grid-cols-1 text-white sm:grid-cols-3 lg:grid-cols-6">
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(253,180,22,1) 0%, rgba(247,143,40,1) 100%)" }}
                  >
                    <span className="font-bold text-18 text-gray-darkest">#FF9015</span>
                  </div>
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(138,128,188,1) 0%, rgba(100,55,149,1) 100%)" }}
                  >
                    <span className="font-bold text-18">#60269E</span>
                  </div>
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(226,26,144,1) 0%, rgba(170,33,140,1) 100%)" }}
                  >
                    <span className="font-bold text-18">#A81B8D</span>
                  </div>
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(164,193,229,1) 0%, rgba(73,123,190,1) 100%)" }}
                  >
                    <span className="font-bold text-18 text-gray-darkest">#2A7DE1</span>
                  </div>
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(116,203,203,1) 0%, rgba(0,167,157,1) 100%)" }}
                  >
                    <span className="font-bold text-18 text-gray-darkest">#00A19B</span>
                  </div>
                  <div
                    className="pb-5 pl-5 pt-15"
                    style={{ background: "linear-gradient(90deg, rgba(251,225,78,1) 0%, rgba(243,207,16,1) 100%)" }}
                  >
                    <span className="font-bold text-18 text-gray-darkest">#F2CD00</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-34-42">
          <div className="container flex justify-center">
            <div className="w-full xl:w-10/12">
              <div className="w-full">
                <h3 data-aos="fade-up" className="text-18">
                  Brand typography
                </h3>
              </div>
              <div data-aos="fade-up" className="mt-6">
                <div className="display-desktop">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(fontsDesktopImage)}
                    alt="Fonts Desktop"
                  />
                </div>
                <div className="display-tablet">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(fontsTabletImage)}
                    alt="Fonts Desktop"
                  />
                </div>
                <div className="display-mobile">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(fontsMobileImage)}
                    alt="Fonts Desktop"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-40-77">
          <Section className="col-span-9">
            <Section.Title>Visualizing the excitement of a new platform.</Section.Title>
            <Section.Description>
              <p>
                We brought XCELIS to life with a bold visual identity representing the platform as a powerful force of
                motion. It has a larger-than-life, interactive feel that captures the energy and vibrancy of their
                story.
              </p>
              <p>
                We infused the new messaging and imagery throughout a new XCELIS website, including a product
                architecture and identification system—with recognizable, eye-catching product packaging
                designs—leveraging the visual identity in a customized way for each product portfolio.
              </p>
            </Section.Description>
          </Section>
          <div className="container relative mt-clamp-18-25">
            <Carousel>
              <CursorMarker>
                <CursorMarker.Print />
              </CursorMarker>
              <Carousel.Swiper
                breakpoints={{
                  1436: {
                    spaceBetween: 80,
                    slidesPerView: "auto",
                  },
                }}
              >
                <Carousel.Slide>
                  <div data-aos="fade-up">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneTabletImage)}
                          alt="Print 1"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printOneDesktopImage)}
                          alt="Print 1"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="200">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoTabletImage)}
                          alt="Print 2"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printTwoDesktopImage)}
                          alt="Print 2"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeTabletImage)}
                          alt="Print 3"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printThreeDesktopImage)}
                          alt="Print 3"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFourTabletImage)}
                          alt="Print 4"
                        />
                      </div>
                      <div className="display-desktop">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFourDesktopImage)}
                          alt="Print 4"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
                <Carousel.Slide>
                  <div data-aos="fade-up" data-aos-delay="400">
                    <Carousel.Print>
                      <div className="display-mobile-tablet">
                        <GatsbyImage
                          className="w-full h-full"
                          objectFit="contain"
                          image={getImage(printFiveTabletImage)}
                          alt="Print 5"
                        />
                      </div>
                    </Carousel.Print>
                  </div>
                </Carousel.Slide>
              </Carousel.Swiper>
            </Carousel>
          </div>
          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
        </section>

        <section className="pt-clamp-37-56">
          <div className={`${themeColor} mt-clamp-16-30`}>
            <div className="container relative">
              <div className="absolute inset-0">
                <div className="w-full aspect-w-326 aspect-h-100 sm:aspect-w-1436 sm:aspect-h-270 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="absolute inset-0 flex">
                <div className="w-full mt-auto aspect-w-326 aspect-h-80 sm:aspect-w-1436 sm:aspect-h-158 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
              </div>
              <div className="grid-cols-6 grid-rows-1 sm:grid lg:grid-cols-12 gap-x-4">
                <div className="col-start-1 col-end-5 row-start-1 row-end-2 lg:col-span-7 sm:pt-80 lg:pt-32">
                  <GatsbyImage
                    className="w-full h-full"
                    objectFit="contain"
                    image={getImage(printAdditionalOneImage)}
                    alt="Additional Print - 1"
                  />
                </div>
                <div className="flex col-start-4 col-end-7 row-start-1 row-end-2 mt-20 lg:col-span-5 sm:mt-0">
                  <div className="mb-auto">
                    <GatsbyImage
                      className="w-full h-full"
                      objectFit="contain"
                      image={getImage(printAdditionalTwoImage)}
                      alt="Additional Print - 2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-clamp-35-46 pb-clamp-42-62">
          <div className="container flex justify-center">
            <div className="md:w-10/12">
              <Section.Title>Championing a new brand by channeling the energy of its people.</Section.Title>
              <Section.Description>
                <p className="xl:w-10/12">
                  As part of the new brand platform rollout, JK created an “XCELIS Champions” campaign including both
                  blog articles and a video series, all featured on the new website. Each Champions piece featured a
                  specific DuPont employee and how they make the XCELIS experience real for their customers.
                </p>
                <p className="xl:w-11/12 lg:pr-4">
                  The articles and videos helped connect the value of XCELIS to the people who need to champion it every
                  day—and the customers whose businesses they keep moving forward.
                </p>
              </Section.Description>
            </div>
          </div>
          <div className="container relative flex justify-center mt-clamp-25-34">
            <div className="absolute inset-0">
              <div className="w-full aspect-w-1436 aspect-h-243 bg-white-dark extend before:bg-white-dark after:bg-white-dark"></div>
            </div>
            <div className="flex flex-col w-full lg:flex-row gap-x-4">
              <div className="flex w-full lg:block lg:w-1/2 z-1">
                <div className="w-full sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                    <GatsbyImage image={getImage(webpageOneImage)} alt="AD webpage - 1" />
                  </div>
                </div>
              </div>
              <div className="flex w-full lg:block lg:w-1/2 pt-clamp-14-37 sm:pt-0 sm:-mt-10 lg:mt-0 lg:pt-clamp-14-37">
                <div className="w-full ml-auto sm:w-5/6 lg:w-full">
                  <div data-aos="fade-up" className="overflow-hidden rounded shadow-xl">
                    <GatsbyImage image={getImage(webpageTwoImage)} alt="AD webpage - 2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Arrow colorTop="bg-gray-darkest" colorBottom="bg-white" fillColor="text-white" />

        <Pagination client="xcelis" />
      </main>
    </Layout>
  )
}

export default XcelisPage

export const xcelisQuery = graphql`
  query XcelisPageQuery {
    logoTwoImage: file(relativePath: { eq: "work/xcelis/logo-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoThreeImage: file(relativePath: { eq: "work/xcelis/logo-03.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    logoFourImage: file(relativePath: { eq: "work/xcelis/logo-04.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageOneImage: file(relativePath: { eq: "work/xcelis/webpage-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    webpageTwoImage: file(relativePath: { eq: "work/xcelis/webpage-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneDesktopImage: file(relativePath: { eq: "work/xcelis/print-01-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printOneTabletImage: file(relativePath: { eq: "work/xcelis/print-01-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoDesktopImage: file(relativePath: { eq: "work/xcelis/print-02-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printTwoTabletImage: file(relativePath: { eq: "work/xcelis/print-02-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeDesktopImage: file(relativePath: { eq: "work/xcelis/print-03-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printThreeTabletImage: file(relativePath: { eq: "work/xcelis/print-03-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFourDesktopImage: file(relativePath: { eq: "work/xcelis/print-04-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFourTabletImage: file(relativePath: { eq: "work/xcelis/print-04-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printFiveTabletImage: file(relativePath: { eq: "work/xcelis/print-05-tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalOneImage: file(relativePath: { eq: "work/xcelis/print-additional-01.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    printAdditionalTwoImage: file(relativePath: { eq: "work/xcelis/print-additional-02.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fontsDesktopImage: file(relativePath: { eq: "work/xcelis/fonts-desktop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fontsTabletImage: file(relativePath: { eq: "work/xcelis/fonts-tablet.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    fontsMobileImage: file(relativePath: { eq: "work/xcelis/fonts-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
